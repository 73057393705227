import React from 'react';

import PropTypes from 'prop-types';

import Footer from 'components/_layouts/Footer';
import Header from 'components/_layouts/Header';
import Scripts from 'components/_layouts/Scripts';

const propTypes = {
  children: PropTypes.node,
  hideSearch: PropTypes.bool,
  title: PropTypes.node,
  extraHight: PropTypes.bool,
  titlePosition: PropTypes.string,
  simpleHeader: PropTypes.bool,
  withoutFooter: PropTypes.bool,
};

const Layout = ({ children, hideSearch, title, extraHight, titlePosition, simpleHeader, withoutFooter }) => (
  <div className="flex flex-col min-h-screen">
    <Scripts />
    <Header
      hideSearch={hideSearch}
      title={title}
      extraHight={extraHight}
      position={titlePosition}
      simpleHeader={simpleHeader}
    />
    <main className="flex flex-col flex-grow flex-shrink h-full" style={{ flexBasis: 0 }}>
      {children}
    </main>
    {!withoutFooter && <Footer />}
  </div>
);

Layout.propTypes = propTypes;

export default Layout;
