export enum MainNavLink {
  SEARCH = 'SEARCH',
  PLANS = 'PLANS',
  LIST_MY_BUSINESS = 'LIST_MY_BUSINESS',
  HOME_USER = 'HOME_USER',
  DISCOVER = 'DISCOVER',
  HOME_COMPANY = 'HOME_COMPANY',
  PULSO = 'PULSO',
  PROFILE = 'PROFILE',
}
