import { extractRequestInfo } from './extractRequestInfo';

const API_URL = process.env.API_URL;

const errorPatterns = [
  { url: new RegExp(`${API_URL}/companies/([0-9a-fA-F]{32})`), status: 404 },
  { url: new RegExp(`${API_URL}/search.*`), status: 404 },
  { url: new RegExp(`${API_URL}/locations.*`), status: 404 },
];

export function logInfoOrError({ method, url, data = {}, error, headers, context }) {
  if (typeof window !== 'undefined') {
    // Client-side case
    // winston doesn't work in client env
  } else {
    // Server-side case
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { logger } = require('utils/logger');
    const message = extractRequestInfo(method, url, data, headers);
    if (error) {
      const matchedPattern = errorPatterns.find((pattern) => pattern.url.test(url));
      if (matchedPattern && error?.response?.status === matchedPattern.status) {
        logger.filteredError(message, error, context);
      } else {
        logger.error(message, error, context);
      }
    } else {
      logger.log(message, context);
    }
  }
}
