import { AdminCompanyDto, CompanyStatusEnum } from 'store/models/Company';
import { User } from 'store/models/User';

import { MainNavLink } from '../constants';

export function getEnablePulso(): boolean {
  return process.env.NEXT_PUBLIC_ENABLE_PULSO === 'true';
}

export function getEnablePulsoCountry(): string[] {
  const countriesString = process.env.NEXT_PUBLIC_ENABLE_PULSO_COUNTRY;

  if (!countriesString) {
    // If undefined, return an empty array
    return [];
  }

  const countries = countriesString.split(',');
  const uppercaseCountries = countries.map((country) => country.trim().toUpperCase());
  return uppercaseCountries;
}

export function getMainDynamicNavLinks(user?: User): MainNavLink[] {
  const links: MainNavLink[] = [];
  if (user) {
    if (user.currentCompany) {
      links.push(MainNavLink.HOME_COMPANY); // always show

      if (user.currentCompany.status === CompanyStatusEnum.ACTIVE && user.currentCompany.duns) {
        const countryHasPulsoAvailable = getEnablePulsoCountry().includes(
          AdminCompanyDto.getCountryCode(user.currentCompany),
        );
        if (getEnablePulso() && countryHasPulsoAvailable) {
          links.push(MainNavLink.PULSO);
        }
        links.push(MainNavLink.PROFILE);
      }

      links.push(MainNavLink.DISCOVER); // always show
    } else {
      links.push(MainNavLink.HOME_USER);
      if (getEnablePulso()) {
        links.push(MainNavLink.PULSO);
      }
      links.push(MainNavLink.DISCOVER);
    }
  } else {
    links.push(MainNavLink.SEARCH);
    links.push(MainNavLink.PLANS);
    if (getEnablePulso()) {
      // pulso is not available for dunsguide israel
      links.push(MainNavLink.PULSO);
    }
    links.push(MainNavLink.LIST_MY_BUSINESS);
  }

  return links;
}
