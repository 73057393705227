import React, { useState, useContext } from 'react';

import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { Button } from '_ui/_common/Button';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { languageMeta } from 'utils/localeConstants';

type Props = {
  onLanguageSwitch: (value: string) => void;
  onCancel: () => void;
  language: string;
};

const LanguageBanner = ({ language, onLanguageSwitch, onCancel }: Props) => {
  const { t } = useTranslation();
  const { supportedLanguages } = useContext(InternationalizationContext);
  const [userPreferredLanguage, setUserPreferredLanguage] = useState(language);

  const fullNameSupportedLanguages = (supportedLanguages as string[]).map((lang) => ({
    value: lang,
    label: t(languageMeta[lang]?.label.toLowerCase(), { lng: userPreferredLanguage }),
    direction: languageMeta[lang]?.direction || 'ltr',
  }));

  return (
    <div className="flex flex-wrap justify-between items-center p-2">
      <div className="flex">
        <div className="text-lg font-medium leading-7 text-white">
          {t('banner_text', {
            lng: userPreferredLanguage,
            language: t(languageMeta[userPreferredLanguage]?.label.toLowerCase(), { lng: userPreferredLanguage }),
          })}
        </div>
      </div>
      <div className="flex flex-wrap gap-x-4 items-center">
        <div className="flex items-center">
          <select
            onChange={(event) => {
              setUserPreferredLanguage(event.target.value);
            }}
            className={`items-center w-64 text-sm leading-5 font-medium py-2 px-3 sm:text-sm placeholder-gray-500 text-gray-700 rounded-md border-gray-300 form-control focus:border-indigo-500 focus:ring-indigo-500 shadow-sm focus:outline-none focus:shadow-outline`}
          >
            {fullNameSupportedLanguages.map((lang) => {
              return (
                <option
                  className="bg-white"
                  key={lang.label}
                  value={lang.value}
                  selected={lang.value === userPreferredLanguage}
                >
                  {lang.label}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex gap-x-6 items-center">
          <Button
            theme="primary"
            onClick={() => {
              onLanguageSwitch(userPreferredLanguage);
            }}
            data-testid="banner--preferred_language-select_button​"
          >
            {t('switch language', { lng: userPreferredLanguage })}
          </Button>

          <Button
            shape="square"
            variant="text"
            theme="dark"
            onClick={onCancel}
            data-testid="banner--preferred_language-cancel_button​"
          >
            <XIcon className="w-8 h-8 text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export { LanguageBanner };
