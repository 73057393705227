import React from 'react';

import classNames from 'classnames';
import { Image } from 'cloudinary-react';
import PropTypes from 'prop-types';

import LoadingOverlay from 'components/LoadingOverlay';

const propTypes = {
  path: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  crop: PropTypes.string,
  loading: PropTypes.bool,
};

const ProfileImage = ({
  path,
  alt,
  className = '',
  crop = 'pad',
  background = 'white',
  width = 180,
  height = 180,
  loading = false,
  ...rest
}) => (
  <LoadingOverlay loading={loading}>
    <Image
      cloudName="dunsguide"
      className={classNames('rounded', className)}
      publicId={path || '/defaults/default_company_logo.png'}
      defaultImage="defaults:default_company_logo.png"
      alt={path ? alt : 'Default company avatar'}
      width={width}
      height={height}
      crop={crop}
      background={background}
      dpr="auto"
      {...rest}
    />
  </LoadingOverlay>
);

ProfileImage.propTypes = propTypes;

export default ProfileImage;
