import React, { useContext } from 'react';

import classNames from 'classnames';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import { isDesktop } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import { UserDropdown } from '_ui/_navigation/UserDropdown';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { RouterContext } from 'components/_context/RouterContext';
import Link from 'components/Link';
import dunsguideLogo from 'public/images/dunsguide-logo-small.png';
import { api } from 'store/api';
import { useUser } from 'store/hooks';
import { replaceDirection } from 'utils/directionFormat';

import CookieNames from 'constants/cookieNames';

const propTypes = {
  title: PropTypes.node,
};

const SimpleHeader = ({ title }) => {
  const user = useUser();
  const { getTranslated, direction } = useContext(InternationalizationContext);
  const { router } = useContext(RouterContext);
  const dispatch = useDispatch();
  const posthog = usePostHog();

  if (!user) {
    return null;
  }

  return (
    <header className="bg-brand-dark" dir="ltr">
      <div
        className={classNames('pl-4 md:pl-8 lg:pl-2 pr-4 md:pr-6 lg:pr-0', {
          'mx-auto w-full max-w-screen-xl': isDesktop,
        })}
      >
        <Navbar variant="dark" expand="lg" className="flex justify-between items-center my-0 -mx-4">
          <Link className="flex justify-center items-center h-14" href="/" data-testid="header_home_link">
            <Image
              src={dunsguideLogo}
              alt={getTranslated({
                en: 'Dunsguide logo',
                es: 'Logotipo de Dunsguide',
                br: 'Logotipo do Dunsguide',
              })}
              height={36}
              width={36}
              priority={true}
              style={{
                height: 'auto',
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: replaceDirection('left', direction),
              }}
            />
          </Link>

          <div className="flex justify-center items-center">
            <div className="text-lg text-white">{title}</div>
          </div>

          <div className="flex w-12 md:w-14 lg:w-auto h-12 md:h-14 lg:h-auto">
            <UserDropdown
              user={user}
              onLogoutClick={() => {
                if (posthog.__loaded) {
                  posthog.reset(true);
                }
                router.push('/auth/logout');
              }}
              onCompanyClick={(id) => {
                const nextCurrentCompany = user.companies.find((company) => company.aclCompanyId === id);
                Cookies.set(CookieNames.currentCompanyId, id);
                // Update the 'getUser' query data in Redux manually to reflect the change in the current company.
                dispatch(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  api.util.updateQueryData('getUser', undefined, (user) => {
                    user.currentCompany = nextCurrentCompany;
                  }),
                );
              }}
              className='className="mr-0 lg:mr-6"'
            />
          </div>
        </Navbar>
      </div>
    </header>
  );
};

SimpleHeader.propTypes = propTypes;

export default SimpleHeader;
