export const footerSubjects = [
  {
    id: 99994075,
    description: {
      en: 'Transport',
      es: 'Transporte',
      pt: 'Transporte',
    },
    slug: {
      en: 'transport-99994075',
      es: 'transporte-99994075',
      pt: 'transporte-99994075',
    },
  },
  {
    id: 99994111,
    description: {
      en: 'Textile',
      es: 'Textil',
      pt: 'T\u00eaxteis',
    },
    slug: {
      en: 'textile-99994111',
      es: 'textil-99994111',
      pt: 'texteis-99994111',
    },
  },
  {
    id: 99994143,
    description: {
      en: 'Professional Writing & Shorthand',
      es: 'Escritura y taquigraf\u00eda profesional',
      pt: 'Servi\u00e7os de reda\u00e7\u00e3o profissional e de taquigrafia',
    },
    slug: {
      en: 'professional-writing-shorthand-99994143',
      es: 'escritura-y-taquigrafia-profesional-99994143',
      pt: 'servicos-de-redacao-profissional-e-de-taquigrafia-99994143',
    },
  },
  {
    id: 99994150,
    description: {
      en: 'Food And Food Products',
      es: 'Alimentos y productos alimenticios',
      pt: 'Alimentos e produtos aliment\u00edcios',
    },
    slug: {
      en: 'food-and-food-products-99994150',
      es: 'alimentos-y-productos-alimenticios-99994150',
      pt: 'alimentos-e-produtos-alimenticios-99994150',
    },
  },
  {
    id: 99994215,
    description: {
      en: 'Real Estate',
      es: 'Bienes ra\u00edces',
      pt: 'Im\u00f3veis',
    },
    slug: {
      en: 'real-estate-99994215',
      es: 'bienes-raices-99994215',
      pt: 'imoveis-99994215',
    },
  },
  {
    id: 99994282,
    description: {
      en: 'Construction And Residential Contractor',
      es: 'Contratista de construcci\u00f3n y residencial',
      pt: 'Empreiteiros para constru\u00e7\u00e3o civil e residencial',
    },
    slug: {
      en: 'construction-and-residential-contractor-99994282',
      es: 'contratista-de-construccion-y-residencial-99994282',
      pt: 'empreiteiros-para-construcao-civil-e-residencial-99994282',
    },
  },
  {
    id: 99994291,
    description: {
      en: 'Furniture',
      es: 'Muebles',
      pt: 'M\u00f3veis',
    },
    slug: {
      en: 'furniture-99994291',
      es: 'muebles-99994291',
      pt: 'moveis-99994291',
    },
  },
  {
    id: 99994292,
    description: {
      en: 'Furniture And Accessories',
      es: 'Muebles y accesorios',
      pt: 'M\u00f3veis e acess\u00f3rios',
    },
    slug: {
      en: 'furniture-and-accessories-99994292',
      es: 'muebles-y-accesorios-99994292',
      pt: 'moveis-e-acessorios-99994292',
    },
  },
  {
    id: 99994316,
    description: {
      en: 'Transportation - Tour Bus',
      es: 'Transporte - autob\u00fas de turismo',
      pt: 'Transportes - \u00d4nibus de turismo',
    },
    slug: {
      en: 'transportation-tour-bus-99994316',
      es: 'transporte-autobus-de-turismo-99994316',
      pt: 'transportes-onibus-de-turismo-99994316',
    },
  },
  {
    id: 99994320,
    description: {
      en: 'General Transportation',
      es: 'Transporte general',
      pt: 'Transportes em geral',
    },
    slug: {
      en: 'general-transportation-99994320',
      es: 'transporte-general-99994320',
      pt: 'transportes-em-geral-99994320',
    },
  },
  {
    id: 99994333,
    description: {
      en: 'Professional / Business Training',
      es: 'Capacitaci\u00f3n profesional / comercial',
      pt: 'Forma\u00e7\u00e3o Profissional / Empresarial',
    },
    slug: {
      en: 'professional-business-training-99994333',
      es: 'capacitacion-profesional-comercial-99994333',
      pt: 'formacao-profissional-empresarial-99994333',
    },
  },
  {
    id: 99994393,
    description: {
      en: 'Management',
      es: 'Gesti\u00f3n',
      pt: 'Gest\u00e3o',
    },
    slug: {
      en: 'management-99994393',
      es: 'gestion-99994393',
      pt: 'gestao-99994393',
    },
  },
  {
    id: 99994423,
    description: {
      en: 'Health - Various Services',
      es: 'Salud - distintos servicios',
      pt: 'Sa\u00fade - Servi\u00e7os diversos',
    },
    slug: {
      en: 'health-various-services-99994423',
      es: 'salud-distintos-servicios-99994423',
      pt: 'saude-servicos-diversos-99994423',
    },
  },
  {
    id: 99994453,
    description: {
      en: 'Financial Services',
      es: 'Servicios comerciales',
      pt: 'Servi\u00e7os Financeiros',
    },
    slug: {
      en: 'financial-services-99994453',
      es: 'servicios-comerciales-99994453',
      pt: 'servicos-financeiros-99994453',
    },
  },
  {
    id: 99994506,
    description: {
      en: 'Beauty Salons',
      es: 'Salones de belleza',
      pt: 'Sal\u00f5es de Beleza',
    },
    slug: {
      en: 'beauty-salons-99994506',
      es: 'salones-de-belleza-99994506',
      pt: 'saloes-de-beleza-99994506',
    },
  },
  {
    id: 99994512,
    description: {
      en: 'Investment Companies',
      es: 'Compa\u00f1\u00edas de inversi\u00f3n',
      pt: 'Companhias de Investimento',
    },
    slug: {
      en: 'investment-companies-99994512',
      es: 'companias-de-inversion-99994512',
      pt: 'companhias-de-investimento-99994512',
    },
  },
  {
    id: 99994535,
    description: {
      en: 'Banks',
      es: 'Bancos',
      pt: 'Bancos',
    },
    slug: {
      en: 'banks-99994535',
      es: 'bancos-99994535',
      pt: 'bancos-99994535',
    },
  },
  {
    id: 99994539,
    description: {
      en: 'Food - Restaurants',
      es: 'Alimentos - restaurantes',
      pt: 'Alimentos - Restaurantes',
    },
    slug: {
      en: 'food-restaurants-99994539',
      es: 'alimentos-restaurantes-99994539',
      pt: 'alimentos-restaurantes-99994539',
    },
  },
  {
    id: 99994540,
    description: {
      en: 'Computers - Computers Software And Accessories',
      es: 'Computadoras - software y accesorios de computadoras',
      pt: 'Computadores - Software e Acess\u00f3rios para Computadores',
    },
    slug: {
      en: 'computers-computers-software-and-accessories-99994540',
      es: 'computadoras-software-y-accesorios-de-computadoras-99994540',
      pt: 'computadores-software-e-acessorios-para-computadores-99994540',
    },
  },
  {
    id: 99994546,
    description: {
      en: 'Vehicles Accessories',
      es: 'Accesorios para veh\u00edculos',
      pt: 'Acess\u00f3rios para Ve\u00edculos',
    },
    slug: {
      en: 'vehicles-accessories-99994546',
      es: 'accesorios-para-vehiculos-99994546',
      pt: 'acessorios-para-veiculos-99994546',
    },
  },
  {
    id: 99994640,
    description: {
      en: 'Vehicles',
      es: 'Veh\u00edculos',
      pt: 'Ve\u00edculos',
    },
    slug: {
      en: 'vehicles-99994640',
      es: 'vehiculos-99994640',
      pt: 'veiculos-99994640',
    },
  },
  {
    id: 99994841,
    description: {
      en: 'Bottled And Canned Soft Drinks',
      es: 'Refrescos embotellados y en lata',
      pt: 'Refrigerantes em Garrafas e em Latas',
    },
    slug: {
      en: 'bottled-and-canned-soft-drinks-99994841',
      es: 'refrescos-embotellados-y-en-lata-99994841',
      pt: 'refrigerantes-em-garrafas-e-em-latas-99994841',
    },
  },
  {
    id: 99994866,
    description: {
      en: 'Electrical Contractors',
      es: 'Contratistas el\u00e9ctricos',
      pt: 'Empreiteiros de Servi\u00e7os El\u00e9tricos',
    },
    slug: {
      en: 'electrical-contractors-99994866',
      es: 'contratistas-electricos-99994866',
      pt: 'empreiteiros-de-servicos-eletricos-99994866',
    },
  },
  {
    id: 99994868,
    description: {
      en: 'Electrical Works',
      es: 'Trabajos el\u00e9ctricos',
      pt: 'Trabalhos de Eletricidade',
    },
    slug: {
      en: 'electrical-works-99994868',
      es: 'trabajos-electricos-99994868',
      pt: 'trabalhos-de-eletricidade-99994868',
    },
  },
  {
    id: 99994884,
    description: {
      en: 'Industrial Buildings - New Construction',
      es: 'Edificios industriales - construcci\u00f3n nueva',
      pt: 'Pr\u00e9dios Industriais - Novas Constru\u00e7\u00f5es',
    },
    slug: {
      en: 'industrial-buildings-new-construction-99994884',
      es: 'edificios-industriales-construccion-nueva-99994884',
      pt: 'predios-industriais-novas-construcoes-99994884',
    },
  },
  {
    id: 99995204,
    description: {
      en: 'Gift Vouchers Promotion And Redemption',
      es: 'Promoci\u00f3n y reembolso de vales para obsequio',
      pt: 'Promo\u00e7\u00e3o e Troca de Cupom de Presente',
    },
    slug: {
      en: 'gift-vouchers-promotion-and-redemption-99995204',
      es: 'promocion-y-reembolso-de-vales-para-obsequio-99995204',
      pt: 'promocao-e-troca-de-cupom-de-presente-99995204',
    },
  },
  {
    id: 99995300,
    description: {
      en: 'Clothing And Garments',
      es: 'Vestimentas y prendas',
      pt: 'Vestu\u00e1rio e trajes',
    },
    slug: {
      en: 'clothing-and-garments-99995300',
      es: 'vestimentas-y-prendas-99995300',
      pt: 'vestuario-e-trajes-99995300',
    },
  },
  {
    id: 99995363,
    description: {
      en: 'Religious organizations',
      es: 'Organizaciones religiosas',
      pt: 'Organiza\u00e7\u00f5es Religiosas',
    },
    slug: {
      en: 'religious-organizations-99995363',
      es: 'organizaciones-religiosas-99995363',
      pt: 'organizacoes-religiosas-99995363',
    },
  },
  {
    id: 99995401,
    description: {
      en: 'A visit to the doctor',
      es: 'Una visita al m\u00e9dico',
      pt: 'Uma Visita ao M\u00e9dico',
    },
    slug: {
      en: 'a-visit-to-the-doctor-99995401',
      es: 'una-visita-al-medico-99995401',
      pt: 'uma-visita-ao-medico-99995401',
    },
  },
  {
    id: 99995484,
    description: {
      en: 'Associations and Organizations',
      es: 'Asociaciones y Organizaciones de ayuda al animal',
      pt: 'Associa\u00e7\u00f5es e Organiza\u00e7\u00f5es',
    },
    slug: {
      en: 'associations-and-organizations-99995484',
      es: 'asociaciones-y-organizaciones-de-ayuda-al-animal-99995484',
      pt: 'associacoes-e-organizacoes-99995484',
    },
  },
  {
    id: 99995511,
    description: {
      en: 'Bakeries and pastry shops',
      es: 'Tiendas de panader\u00eda y pasteler\u00eda',
      pt: 'Padarias e Confeitarias',
    },
    slug: {
      en: 'bakeries-and-pastry-shops-99995511',
      es: 'tiendas-de-panaderia-y-pasteleria-99995511',
      pt: 'padarias-e-confeitarias-99995511',
    },
  },
  {
    id: 99995575,
    description: {
      en: 'Building materials stores',
      es: 'Tiendas de materiales de construcci\u00f3n',
      pt: 'Lojas de materiais de constru\u00e7\u00e3o',
    },
    slug: {
      en: 'building-materials-stores-99995575',
      es: 'tiendas-de-materiales-de-construccion-99995575',
      pt: 'lojas-de-materiais-de-construcao-99995575',
    },
  },
  {
    id: 99995665,
    description: {
      en: 'Cleaning and maintenance of buildings and institutions',
      es: 'Limpieza y mantenimiento de edificios e instituciones',
      pt: 'Limpeza e manuten\u00e7\u00e3o de edif\u00edcios e institui\u00e7\u00f5es',
    },
    slug: {
      en: 'cleaning-and-maintenance-of-buildings-and-institutions-99995665',
      es: 'limpieza-y-mantenimiento-de-edificios-e-instituciones-99995665',
      pt: 'limpeza-e-manutencao-de-edificios-e-instituicoes-99995665',
    },
  },
  {
    id: 99995677,
    description: {
      en: 'Clothing and Apparel',
      es: 'Ropa y confecci\u00f3n',
      pt: 'Roupas e confec\u00e7\u00e3o',
    },
    slug: {
      en: 'clothing-and-apparel-99995677',
      es: 'ropa-y-confeccion-99995677',
      pt: 'roupas-e-confeccao-99995677',
    },
  },
  {
    id: 99995691,
    description: {
      en: 'Coffee shops',
      es: 'Servicios para servir caf\u00e9',
      pt: 'Lanchonetes',
    },
    slug: {
      en: 'coffee-shops-99995691',
      es: 'servicios-para-servir-cafe-99995691',
      pt: 'lanchonetes-99995691',
    },
  },
  {
    id: 99995698,
    description: {
      en: 'Colors and trowels',
      es: 'Colores y paleta',
      pt: 'Tintas e paletas',
    },
    slug: {
      en: 'colors-and-trowels-99995698',
      es: 'colores-y-paleta-99995698',
      pt: 'tintas-e-paletas-99995698',
    },
  },
  {
    id: 99995711,
    description: {
      en: 'Computer Peripherals',
      es: 'Perif\u00e9ricos de computaci\u00f3n',
      pt: 'Perif\u00e9ricos de computa\u00e7\u00e3o',
    },
    slug: {
      en: 'computer-peripherals-99995711',
      es: 'perifericos-de-computacion-99995711',
      pt: 'perifericos-de-computacao-99995711',
    },
  },
  {
    id: 99995713,
    description: {
      en: 'Computer Repair',
      es: 'Reparaci\u00f3n de computadoras',
      pt: 'Reparos de computadores',
    },
    slug: {
      en: 'computer-repair-99995713',
      es: 'reparacion-de-computadoras-99995713',
      pt: 'reparos-de-computadores-99995713',
    },
  },
  {
    id: 99995719,
    description: {
      en: 'Computers',
      es: 'Computadoras',
      pt: 'Computadores',
    },
    slug: {
      en: 'computers-99995719',
      es: 'computadoras-99995719',
      pt: 'computadores-99995719',
    },
  },
  {
    id: 99995727,
    description: {
      en: 'Conferences and conventions',
      es: 'Conferencias y convenciones',
      pt: 'Confer\u00eancias e conven\u00e7\u00f5es',
    },
    slug: {
      en: 'conferences-and-conventions-99995727',
      es: 'conferencias-y-convenciones-99995727',
      pt: 'conferencias-e-convencoes-99995727',
    },
  },
  {
    id: 99995744,
    description: {
      en: 'Contracting and infrastructure building',
      es: 'Contrataci\u00f3n y construcci\u00f3n de infraestructura',
      pt: 'Contrata\u00e7\u00e3o e constru\u00e7\u00e3o de infraestrutura',
    },
    slug: {
      en: 'contracting-and-infrastructure-building-99995744',
      es: 'contratacion-y-construccion-de-infraestructura-99995744',
      pt: 'contratacao-e-construcao-de-infraestrutura-99995744',
    },
  },
  {
    id: 99995745,
    description: {
      en: 'Contractor companies',
      es: 'Compa\u00f1\u00edas contratistas',
      pt: 'Empresas de empreitada',
    },
    slug: {
      en: 'contractor-companies-99995745',
      es: 'companias-contratistas-99995745',
      pt: 'empresas-de-empreitada-99995745',
    },
  },
  {
    id: 99995766,
    description: {
      en: 'Cosmetics and Toiletries',
      es: 'Cosm\u00e9ticos y art\u00edculos para tocador',
      pt: 'Cosm\u00e9ticos e artigos de higiene pessoal',
    },
    slug: {
      en: 'cosmetics-and-toiletries-99995766',
      es: 'cosmeticos-y-articulos-para-tocador-99995766',
      pt: 'cosmeticos-e-artigos-de-higiene-pessoal-99995766',
    },
  },
  {
    id: 99995775,
    description: {
      en: 'Courier',
      es: 'Mensajer\u00eda',
      pt: 'Mensageiro',
    },
    slug: {
      en: 'courier-99995775',
      es: 'mensajeria-99995775',
      pt: 'mensageiro-99995775',
    },
  },
  {
    id: 99995779,
    description: {
      en: 'Credit and discounting checks',
      es: 'Cr\u00e9dito y descuento de cheques',
      pt: 'Cr\u00e9dito e desconto de cheques',
    },
    slug: {
      en: 'credit-and-discounting-checks-99995779',
      es: 'credito-y-descuento-de-cheques-99995779',
      pt: 'credito-e-desconto-de-cheques-99995779',
    },
  },
  {
    id: 99995833,
    description: {
      en: 'Directory',
      es: 'Directorio',
      pt: 'Diret\u00f3rio',
    },
    slug: {
      en: 'directory-99995833',
      es: 'directorio-99995833',
      pt: 'diretorio-99995833',
    },
  },
  {
    id: 99995857,
    description: {
      en: 'Dressing equipment',
      es: 'Equipo de vendaje',
      pt: 'Equipamento de limpeza/ acabamento',
    },
    slug: {
      en: 'dressing-equipment-99995857',
      es: 'equipo-de-vendaje-99995857',
      pt: 'equipamento-de-limpeza-acabamento-99995857',
    },
  },
  {
    id: 99995891,
    description: {
      en: 'Electrical services and contractors',
      es: 'Servicios y contratistas el\u00e9ctricos',
      pt: 'Servi\u00e7os el\u00e9tricos e empreiteros',
    },
    slug: {
      en: 'electrical-services-and-contractors-99995891',
      es: 'servicios-y-contratistas-electricos-99995891',
      pt: 'servicos-eletricos-e-empreiteros-99995891',
    },
  },
  {
    id: 99995933,
    description: {
      en: 'Event Production and Design',
      es: 'Producci\u00f3n y dise\u00f1o de eventos',
      pt: 'Organiza\u00e7\u00e3o e Produ\u00e7\u00e3o de Eventos',
    },
    slug: {
      en: 'event-production-and-design-99995933',
      es: 'produccion-y-diseno-de-eventos-99995933',
      pt: 'organizacao-e-producao-de-eventos-99995933',
    },
  },
  {
    id: 99995990,
    description: {
      en: 'Food and beverage stores',
      es: 'Tiendas de alimentos y bebidas',
      pt: 'Lojas de alimentos e bebidas',
    },
    slug: {
      en: 'food-and-beverage-stores-99995990',
      es: 'tiendas-de-alimentos-y-bebidas-99995990',
      pt: 'lojas-de-alimentos-e-bebidas-99995990',
    },
  },
  {
    id: 99996018,
    description: {
      en: 'Furniture stores',
      es: 'Muebler\u00edas',
      pt: 'Lojas de m\u00f3veis',
    },
    slug: {
      en: 'furniture-stores-99996018',
      es: 'mueblerias-99996018',
      pt: 'lojas-de-moveis-99996018',
    },
  },
  {
    id: 99996019,
    description: {
      en: 'Gadgets and unique gifts',
      es: 'Gadgets y regalos \u00fanicos',
      pt: 'Utens\u00edlios e presentes exclusivos',
    },
    slug: {
      en: 'gadgets-and-unique-gifts-99996019',
      es: 'gadgets-y-regalos-unicos-99996019',
      pt: 'utensilios-e-presentes-exclusivos-99996019',
    },
  },
  {
    id: 99996023,
    description: {
      en: 'Garages',
      es: 'Garajes',
      pt: 'Oficinas',
    },
    slug: {
      en: 'garages-99996023',
      es: 'garajes-99996023',
      pt: 'oficinas-99996023',
    },
  },
  {
    id: 99996068,
    description: {
      en: 'Grocery Stores',
      es: 'Tiendas de comestibles',
      pt: 'Mercearia',
    },
    slug: {
      en: 'grocery-stores-99996068',
      es: 'tiendas-de-comestibles-99996068',
      pt: 'mercearia-99996068',
    },
  },
  {
    id: 99996103,
    description: {
      en: 'Hitech',
      es: 'Servicios de alta tecnolog\u00eda',
      pt: 'Hitech',
    },
    slug: {
      en: 'hitech-99996103',
      es: 'servicios-de-alta-tecnologia-99996103',
      pt: 'hitech-99996103',
    },
  },
  {
    id: 99996143,
    description: {
      en: 'immigrants aid organizations',
      es: 'Organizaciones de ayuda al inmigrante',
      pt: 'organiza\u00e7\u00f5es de apoio ao imigrante',
    },
    slug: {
      en: 'immigrants-aid-organizations-99996143',
      es: 'organizaciones-de-ayuda-al-inmigrante-99996143',
      pt: 'organizacoes-de-apoio-ao-imigrante-99996143',
    },
  },
  {
    id: 99996282,
    description: {
      en: 'Maids',
      es: 'Mucamas',
      pt: 'Empregadas dom\u00e9sticas',
    },
    slug: {
      en: 'maids-99996282',
      es: 'mucamas-99996282',
      pt: 'empregadas-domesticas-99996282',
    },
  },
  {
    id: 99996292,
    description: {
      en: 'Management and maintenance of properties',
      es: 'Gesti\u00f3n y mantenimiento de propiedades',
      pt: 'Gest\u00e3o e manuten\u00e7\u00e3o de propriedades',
    },
    slug: {
      en: 'management-and-maintenance-of-properties-99996292',
      es: 'gestion-y-mantenimiento-de-propiedades-99996292',
      pt: 'gestao-e-manutencao-de-propriedades-99996292',
    },
  },
  {
    id: 99996308,
    description: {
      en: 'Masonry and ancillary works',
      es: 'Trabajos de mamposter\u00eda y auxiliares',
      pt: 'Alvenaria e obras auxiliares',
    },
    slug: {
      en: 'masonry-and-ancillary-works-99996308',
      es: 'trabajos-de-mamposteria-y-auxiliares-99996308',
      pt: 'alvenaria-e-obras-auxiliares-99996308',
    },
  },
  {
    id: 99996314,
    description: {
      en: 'Meat and fish',
      es: 'Carne y pescado',
      pt: 'Carne e peixe',
    },
    slug: {
      en: 'meat-and-fish-99996314',
      es: 'carne-y-pescado-99996314',
      pt: 'carne-e-peixe-99996314',
    },
  },
  {
    id: 99996329,
    description: {
      en: "Men's fashion",
      es: 'Moda de caballeros',
      pt: 'Moda masculina',
    },
    slug: {
      en: 'men-s-fashion-99996329',
      es: 'moda-de-caballeros-99996329',
      pt: 'moda-masculina-99996329',
    },
  },
  {
    id: 99996356,
    description: {
      en: 'Miscellaneous Retail Stores',
      es: 'Tiendas minoristas varias',
      pt: 'Lojas de varejo de produtos diversos',
    },
    slug: {
      en: 'miscellaneous-retail-stores-99996356',
      es: 'tiendas-minoristas-varias-99996356',
      pt: 'lojas-de-varejo-de-produtos-diversos-99996356',
    },
  },
  {
    id: 99996382,
    description: {
      en: 'Nail building',
      es: 'Clavos de construcci\u00f3n',
      pt: 'Pregos de constru\u00e7\u00e3o',
    },
    slug: {
      en: 'nail-building-99996382',
      es: 'clavos-de-construccion-99996382',
      pt: 'pregos-de-construcao-99996382',
    },
  },
  {
    id: 99996399,
    description: {
      en: 'Deliveries',
      es: 'Entregas',
      pt: 'Entregas',
    },
    slug: {
      en: 'deliveries-99996399',
      es: 'entregas-99996399',
      pt: 'entregas-99996399',
    },
  },
  {
    id: 99996429,
    description: {
      en: 'Optics',
      es: 'Óptica',
      pt: 'Ótica',
    },
    slug: {
      en: 'optics-99996429',
      es: 'optica-99996429',
      pt: 'otica-99996429',
    },
  },
  {
    id: 99996430,
    description: {
      en: 'Optometrists',
      es: 'Optometristas',
      pt: 'Optometristas',
    },
    slug: {
      en: 'optometrists-99996430',
      es: 'optometristas-99996430',
      pt: 'optometristas-99996430',
    },
  },
  {
    id: 99996442,
    description: {
      en: 'Paint contractors',
      es: 'Contratistas de pintura',
      pt: 'Empreiteiros de pintura',
    },
    slug: {
      en: 'paint-contractors-99996442',
      es: 'contratistas-de-pintura-99996442',
      pt: 'empreiteiros-de-pintura-99996442',
    },
  },
  {
    id: 99996444,
    description: {
      en: 'Painting and wallpapering',
      es: 'Pintura y empapelados',
      pt: 'Pintura e papel de parede',
    },
    slug: {
      en: 'painting-and-wallpapering-99996444',
      es: 'pintura-y-empapelados-99996444',
      pt: 'pintura-e-papel-de-parede-99996444',
    },
  },
  {
    id: 99996477,
    description: {
      en: 'Pharmaceuticals',
      es: 'Farmac\u00e9uticos',
      pt: 'Farmac\u00eauticos',
    },
    slug: {
      en: 'pharmaceuticals-99996477',
      es: 'farmaceuticos-99996477',
      pt: 'farmaceuticos-99996477',
    },
  },
  {
    id: 99996478,
    description: {
      en: 'Pharmacies',
      es: 'Farmacias',
      pt: 'Farm\u00e1cias',
    },
    slug: {
      en: 'pharmacies-99996478',
      es: 'farmacias-99996478',
      pt: 'farmacias-99996478',
    },
  },
  {
    id: 99996508,
    description: {
      en: 'Political organizations',
      es: 'Organizaciones pol\u00edticas',
      pt: 'Organiza\u00e7\u00f5es pol\u00edticas',
    },
    slug: {
      en: 'political-organizations-99996508',
      es: 'organizaciones-politicas-99996508',
      pt: 'organizacoes-politicas-99996508',
    },
  },
  {
    id: 99996517,
    description: {
      en: 'PR',
      es: 'PR',
      pt: 'Rela\u00e7\u00f5es p\u00fablicas',
    },
    slug: {
      en: 'pr-99996517',
      es: 'pr-99996517',
      pt: 'relacoes-publicas-99996517',
    },
  },
  {
    id: 99996568,
    description: {
      en: 'Pubs and bars',
      es: 'Pubs y bares',
      pt: 'Pubs e bares',
    },
    slug: {
      en: 'pubs-and-bars-99996568',
      es: 'pubs-y-bares-99996568',
      pt: 'pubs-e-bares-99996568',
    },
  },
  {
    id: 99996640,
    description: {
      en: 'Retail',
      es: 'Minorista',
      pt: 'Varejo',
    },
    slug: {
      en: 'retail-99996640',
      es: 'minorista-99996640',
      pt: 'varejo-99996640',
    },
  },
  {
    id: 99996754,
    description: {
      en: 'Social organizations and human rights organizations',
      es: 'Organizaciones sociales y de derechos humanos',
      pt: 'Organiza\u00e7\u00f5es sociais e de direitos humanos',
    },
    slug: {
      en: 'social-organizations-and-human-rights-organizations-99996754',
      es: 'organizaciones-sociales-y-de-derechos-humanos-99996754',
      pt: 'organizacoes-sociais-e-de-direitos-humanos-99996754',
    },
  },
  {
    id: 99996765,
    description: {
      en: 'Spare parts for cars',
      es: 'Repuestos para autom\u00f3viles',
      pt: 'Pe\u00e7as de reposi\u00e7\u00e3o para autom\u00f3veis',
    },
    slug: {
      en: 'spare-parts-for-cars-99996765',
      es: 'repuestos-para-automoviles-99996765',
      pt: 'pecas-de-reposicao-para-automoveis-99996765',
    },
  },
  {
    id: 99996816,
    description: {
      en: 'Supermarkets and groceries',
      es: 'Supermercados y tiendas de comestibles',
      pt: 'Supermercados e mercearias',
    },
    slug: {
      en: 'supermarkets-and-groceries-99996816',
      es: 'supermercados-y-tiendas-de-comestibles-99996816',
      pt: 'supermercados-e-mercearias-99996816',
    },
  },
  {
    id: 99996834,
    description: {
      en: 'Tambour',
      es: 'Tambor',
      pt: 'Tambor',
    },
    slug: {
      en: 'tambour-99996834',
      es: 'tambor-99996834',
      pt: 'tambor-99996834',
    },
  },
  {
    id: 99996869,
    description: {
      en: 'Textile shops',
      es: 'Tiendas textiles',
      pt: 'Lojas t\u00eaxteis',
    },
    slug: {
      en: 'textile-shops-99996869',
      es: 'tiendas-textiles-99996869',
      pt: 'lojas-texteis-99996869',
    },
  },
  {
    id: 99996903,
    description: {
      en: 'Trading on the stock exchange',
      es: 'Comercio en la bolsa de valores',
      pt: 'Com\u00e9rcio na bolsa de valores',
    },
    slug: {
      en: 'trading-on-the-stock-exchange-99996903',
      es: 'comercio-en-la-bolsa-de-valores-99996903',
      pt: 'comercio-na-bolsa-de-valores-99996903',
    },
  },
  {
    id: 99996916,
    description: {
      en: 'Transportation and warehousing',
      es: 'Transporte y dep\u00f3sitos',
      pt: 'Transporte e dep\u00f3sitos',
    },
    slug: {
      en: 'transportation-and-warehousing-99996916',
      es: 'transporte-y-depositos-99996916',
      pt: 'transporte-e-depositos-99996916',
    },
  },
  {
    id: 99996926,
    description: {
      en: 'Inland transportation',
      es: 'Transporte terrestre',
      pt: 'Transporte terrestre',
    },
    slug: {
      en: 'inland-transportation-99996926',
      es: 'transporte-terrestre-99996926',
      pt: 'transporte-terrestre-99996926',
    },
  },
  {
    id: 99996999,
    description: {
      en: 'Women fashion',
      es: 'Moda femenina',
      pt: 'Moda feminina',
    },
    slug: {
      en: 'women-fashion-99996999',
      es: 'moda-femenina-99996999',
      pt: 'moda-feminina-99996999',
    },
  },
  {
    id: 99997050,
    description: {
      en: 'Household furniture',
      es: 'Muebles para el hogar',
      pt: 'M\u00f3veis para a casa',
    },
    slug: {
      en: 'household-furniture-99997050',
      es: 'muebles-para-el-hogar-99997050',
      pt: 'moveis-para-a-casa-99997050',
    },
  },
  {
    id: 99997083,
    description: {
      en: 'Religious Institutions',
      es: 'Instituciones religiosas',
      pt: 'Institui\u00e7\u00f5es religiosas',
    },
    slug: {
      en: 'religious-institutions-99997083',
      es: 'instituciones-religiosas-99997083',
      pt: 'instituicoes-religiosas-99997083',
    },
  },
  {
    id: 99997084,
    description: {
      en: 'Construction materials',
      es: 'Materiales de construcci\u00f3n',
      pt: 'Materiais de constru\u00e7\u00e3o',
    },
    slug: {
      en: 'construction-materials-99997084',
      es: 'materiales-de-construccion-99997084',
      pt: 'materiais-de-construcao-99997084',
    },
  },
  {
    id: 99997088,
    description: {
      en: 'Associations',
      es: 'Asociaciones',
      pt: 'Associa\u00e7\u00f5es',
    },
    slug: {
      en: 'associations-99997088',
      es: 'asociaciones-99997088',
      pt: 'associacoes-99997088',
    },
  },
  {
    id: 99997152,
    description: {
      en: 'Dry construction contractors',
      es: 'Contratistas de acabados',
      pt: 'Constru\u00e7\u00e3o de cabamentos',
    },
    slug: {
      en: 'dry-construction-contractors-99997152',
      es: 'contratistas-de-acabados-99997152',
      pt: 'construcao-de-cabamentos-99997152',
    },
  },
];
