import React from 'react';

const PulseIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.04134 14.7446H6.20102C6.5645 14.7446 6.88588 14.5086 6.99469 14.1618L10.0955 4.2782C10.1609 4.06973 10.4624 4.09026 10.4989 4.30568L13.1529 19.9547C13.1859 20.1494 13.4462 20.1928 13.5406 20.0193L16.1738 15.1789C16.3195 14.9113 16.5998 14.7446 16.9045 14.7446H20.0413"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { PulseIcon };
