import { useContext } from 'react';

import { IsLoggedInContext } from 'components/_context/IsLoggedInContext';

import { USER_CACHE_KEY, useUserQueryState, useGetUserQuery } from './api';
import { User } from './models/User';

// Function to get the type from the selector function
type ReturnTypeFromSelector<T> = T extends (user?: User) => infer R ? R : User | undefined;

// Overload for when selectFromResult is provided
export function useUser<T>(selectFromResult: (user?: User) => T): ReturnTypeFromSelector<typeof selectFromResult>;

// Overload for when selectFromResult is not provided
export function useUser(): User | undefined;

// Implementation
export function useUser<T>(selectFromResult?: (user?: User) => T): User | T | undefined {
  const { isLoggedIn } = useContext<{ isLoggedIn: boolean }>(IsLoggedInContext);

  const { data } = useUserQueryState(USER_CACHE_KEY, {
    skip: !isLoggedIn,
    selectFromResult: ({ data }) => ({
      data: typeof selectFromResult === 'function' ? selectFromResult(data) : data,
    }),
  });

  return data;
}

// Overload for when selectFromResult is provided
export function useUserQuery<T>(selectFromResult: (user?: User) => T): {
  data: T;
  refetch: () => void;
  isFetching: boolean;
};

// Overload for when selectFromResult is not provided
export function useUserQuery(): { data?: User | undefined; refetch: () => void; isFetching: boolean };

// Implementation
export function useUserQuery<T>(selectFromResult?: (user?: User) => T): {
  data: User | T | undefined;
  refetch: () => void;
  isFetching: boolean;
} {
  const { isLoggedIn } = useContext<{ isLoggedIn: boolean }>(IsLoggedInContext);

  const { data, isFetching, refetch } = useGetUserQuery(USER_CACHE_KEY, {
    skip: !isLoggedIn,
    selectFromResult: ({ data, isFetching }) => ({
      data: typeof selectFromResult === 'function' ? selectFromResult(data) : data,
      isFetching,
    }),
  });

  return { data, refetch, isFetching };
}

/* 

example of usage


### with selectors ###

// Example 1: Using useUser without selectFromResult
const user = useUser();
console.log(user); // user is either User or undefined

// Example 2: Using useUser with selectFromResult
const company = useUser(selectCompanyById(123));
console.log(company); // company is either UserCompany or undefined

// Example 3: Using useUserQuery without selectFromResult
const { data, refetch, isFetching } = useUserQuery();
console.log(data); // data is either User or undefined
console.log(refetch); // refetch function
console.log(isFetching); // boolean indicating if the query is currently fetching

// Example 4: Using useUserQuery with selectFromResult
const { data: company, refetch, isFetching } = useUserQuery(selectCompanyById(123));
console.log(company); // selectedUserData is either User or undefined
console.log(refetch); // refetch function for the selected user
console.log(isFetching); // boolean indicating if the query for the selected user is currently fetching


### with getters ###

const user = useUser()
const company = getCompanyById(123, user);
console.log(user); // user is either User or undefined
console.log(company); // company is either UserCompany or undefined

### inline option ###

const aclId = useUser((user) => user.aclId))
console.log(aclId); // aclId is either string or undefined

 */
