import React, { useContext } from 'react';

import { faInstagram, faFacebook, faTwitter, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Container from 'components/_designSystem/_layout/Container';
import Link from 'components/Link';
import { getExternalUrl } from 'utils/externalLinks';

const SimpleFooter = () => {
  const { t } = useTranslation();

  const { language, partner, getTranslated } = useContext(InternationalizationContext);

  const { social } = partner;

  const socialList = [
    { href: social.facebook, icon: faFacebook },
    { href: social.instagram, icon: faInstagram },
    { href: social.linkedin, icon: faLinkedin },
    { href: social.twitter, icon: faTwitter },
    { href: social.youtube, icon: faYoutube },
  ].filter(({ href }) => href);

  return (
    <Container>
      {/* // pb-28 -> padding for bottom navigation used in mobile view */}
      <nav className="flex flex-col px-0 lg:px-4 pt-8 pb-28 lg:pb-10 text-white">
        <div className="flex flex-col sm:flex-row flex-wrap gap-x-8 gap-y-2 justify-center items-center pb-4 border-b lg:justify-normal">
          <div className="items-center">
            <Link
              externalLink
              href={getExternalUrl({ section: 'home', language, addSource: true })}
              variant="light"
              data-testid="footer_link"
            >
              <Image
                src={getTranslated(partner.logoWhite)}
                alt={`${getTranslated(partner.name)} footer logo`}
                height={45}
                width={100}
                style={{
                  maxWidth: '100px',
                  height: '40px',
                  objectFit: 'contain',
                }}
              />
            </Link>
          </div>

          <div className="flex gap-x-4 items-center">
            {socialList.map(({ href, icon }) => (
              <Link href={href} key={href} externalLink data-testid="footer_link">
                <FontAwesomeIcon icon={icon} className="text-3xl text-white hover:text-gray-300" />
              </Link>
            ))}
          </div>
        </div>

        <div className="flex flex-row flex-wrap gap-y-2 justify-center lg:justify-between items-center pt-4">
          <div className="flex flex-wrap gap-x-4 justify-center items-center">
            <Link className="p-2.5 text-center" href="/about" data-testid="footer--about_link">
              {t('about')}
            </Link>
            <Link className="p-2.5 text-center" href="/privacy-policy" data-testid="footer--privacy_policy_link">
              {t('privacy policy')}
            </Link>
            <Link
              className="p-2.5 text-center"
              href="/terms-and-conditions"
              data-testid="footer--terms_and_conditions_link"
            >
              {t('terms and conditions')}
            </Link>
            <Link className="p-2.5 text-center" href="/contact" data-testid="footer--contact_link_button">
              {t('contact')}
            </Link>
          </div>

          <span>
            © {moment().year()} {getTranslated(partner.name)}
          </span>
        </div>
      </nav>
    </Container>
  );
};

export default SimpleFooter;
