import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  withoutPadding: PropTypes.bool,
};

const Container = ({ className = '', children, withoutPadding = false, ...restProps }) => {
  const classNames = twMerge(
    classnames('mx-auto w-full max-w-screen-xl', {
      'px-4': !withoutPadding,
      [className]: !!className,
    }),
  );

  return (
    <div className={classNames} {...restProps}>
      {children}
    </div>
  );
};

Container.propTypes = propTypes;

export default Container;
