import React from 'react';

import { map, slice } from 'lodash';
import PropTypes from 'prop-types';

import Column from 'components/_designSystem/_layout/Column';
import Grid from 'components/_designSystem/_layout/Grid';
import Link from 'components/Link';

const propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
  cols: PropTypes.number,
  itemClass: PropTypes.string,
};

const defaultProps = {
  cols: 1,
};

const ColumnListLinks = ({ values, cols, itemClass }) => {
  const rowsPerColumn = Math.ceil(values.length / cols);
  let index = -1;
  return (
    <Grid cols={cols} className="gap-0 md:gap-8">
      {map(new Array(cols), (value, colIndex) => (
        <Column col={cols} md={1} key={colIndex}>
          {map(slice(values, index + 1, index + 1 + rowsPerColumn), () => {
            index += 1;
            return (
              <div key={values[index].value}>
                <Link className={itemClass} variant="white" href={values[index].href} data-testid="column_list_link">
                  {values[index].value}
                </Link>
              </div>
            );
          })}
        </Column>
      ))}
    </Grid>
  );
};

ColumnListLinks.propTypes = propTypes;
ColumnListLinks.defaultProps = defaultProps;

export default ColumnListLinks;
