import React from 'react';

const DividerIcon = ({ className }: { className: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="3" height="30" viewBox="0 0 3 30" fill="none">
      <line x1="1.98938" y1="-0.000137307" x2="1.98938" y2="30.0001" stroke="white" strokeOpacity="0.4" />
    </svg>
  );
};

export { DividerIcon };
