import React from 'react';

import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';

import { useUser } from 'store/hooks';

import { MainDynamicNavigation } from '../../../_ui/_navigation/MainDynamicNavigation';

import styles from './Footer.module.scss';
import { FooterMenus } from './FooterMenus';
import SimpleFooter from './SimpleFooter';

const propTypes = {
  company: PropTypes.object,
};

const Footer = ({ company }) => {
  const user = useUser();

  return (
    <footer className={`flex-none ${styles.Footer}`}>
      <FooterMenus />

      <SimpleFooter company={company} />
      <Navbar className="flex xl:hidden fixed bottom-0 p-0 w-full bg-white border-t shadow-md z-100">
        <Nav className="flex flex-row w-full">
          <div className="flex flex-row w-full">
            {user && (
              <MainDynamicNavigation
                user={user}
                styles={{
                  color: 'text-gray-500',
                  direction: 'flex-col',
                  hoverColorIcon: 'text-brand-extra-light',
                  hoverColorText: 'text-gray-900',
                  linkActiveColor: 'bg-gray-50',
                  container: 'flex-1',
                }}
              />
            )}
          </div>
        </Nav>
      </Navbar>
    </footer>
  );
};

Footer.propTypes = propTypes;

export default Footer;
