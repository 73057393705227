import React, { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { isDesktop, isTablet } from 'react-device-detect';

import { MainNavigation } from '_ui/_navigation/MainNavigation';
import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import { RouterContext } from 'components/_context/RouterContext';
import SimpleHeader from 'components/_layouts/Header/SimpleHeader';
import { useUser } from 'store/hooks';

import CookieNames from 'constants/cookieNames';

import { LanguageBanner } from './_components/LanguageBanner';
import styles from './Header.module.scss';

const propTypes = {
  hideSearch: PropTypes.bool,
  title: PropTypes.node,
  extraHight: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'center']),
  simpleHeader: PropTypes.bool,
};

const Header = ({ title, extraHight, position = 'center', simpleHeader }) => {
  const user = useUser();
  const { router } = useContext(RouterContext);
  const CIAL_URL = Cookies.get(CookieNames.cialUrl) || process.env.CIAL_URL;

  const { language, geo, selectedLanguage } = useContext(InternationalizationContext);

  // Check if there is a suggested language from geo-location or browser-preferred language and if the language cookie is not set
  // If both conditions are met and the suggested language is different from the app language,
  const [showBanner, setShowBanner] = useState(!selectedLanguage && geo && geo !== language);

  useEffect(() => {
    setShowBanner(!selectedLanguage && geo && geo !== language);
  }, [geo, language, selectedLanguage]);

  if (simpleHeader) {
    return <SimpleHeader title={title} />;
  }

  const className = classNames({
    'mx-auto w-full max-w-screen-xl': isDesktop || isTablet,
    [styles.extra_hight]: extraHight,
  });

  return (
    <header dir="ltr">
      {!user && showBanner && (
        <div className="bg-banner">
          <div className={className}>
            <LanguageBanner
              language={geo}
              onLanguageSwitch={(selectedLanguage) => {
                Cookies.set(CookieNames.selectedLanguage, selectedLanguage);
                router.replace({
                  pathname: `/${selectedLanguage}${router.pathname}`,
                });
                setShowBanner(false);
              }}
              onCancel={() => {
                Cookies.set(CookieNames.selectedLanguage, 'en');
                setShowBanner(false);
              }}
            />
          </div>
        </div>
      )}
      <div className="bg-brand-dark">
        <div className={className}>
          <MainNavigation loginUrl={CIAL_URL} />

          {title ? <h1 className={`${styles.title} ${styles[position]}`}>{title}</h1> : null}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;

export default Header;
