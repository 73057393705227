import React from 'react';

import Script from 'next/script';

const Scripts = () => {
  return (
    <>
      <Script
        id="iubenda-cs-banner-script"
        dangerouslySetInnerHTML={{
          __html: `var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "ZgYoZKoZDL5ioty8hKOlneg2ALSuNUaX"}]);`,
        }}
      />
      <Script src="https://cdn.iubenda.com/cons/iubenda_cons.js" />
      <Script
        type="text/javascript"
        async
        defer
        src={`https://${process.env.NEXT_PUBLIC_HOOVERS_API_KEY}.d41.co/sync/`}
      />
      <Script type="text/javascript" src="https://cdn-0.d41.co/tags/dnb_coretag_v5.min.js" />
      <Script
        id="hoovers-visitor-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.dnbvid?.getData("${process.env.NEXT_PUBLIC_HOOVERS_API_KEY}","json","T",{},function(dnb_Data){
              //Mapping goes here
              if (dnb_Data.status == 200){ 
                //status == 200 represents a match
                //Basic example: myVar = dnb_Data.duns ? dnb_Data.duns : ""
                //Advanced example: myVar = dnb_Data.naicsCodes ? dnb_Data.naicsCodes.substr(1,5) : ""
              }
              
              //The empty array between "T" and "function(dnb_Data)" can be used to pass back data
              //Example: {"p1": "value1", "p2": variable2}
              //"value1" will be recorded on our end as-is
              //variable2 will be recorded as the value of said variable, assuming it is defined
              //"p1" through "p10" exist
            })`,
        }}
      />
    </>
  );
};

export default Scripts;
