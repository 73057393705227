import React from 'react';

import { Image } from 'cloudinary-react';
import PropTypes from 'prop-types';
import ReactAvatar from 'react-avatar';

import UploadCareImage from 'components/_images/UploadCareImage';

const propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  imagePath: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.oneOf(['user', 'company']),
};

const Avatar = ({ name, size, imagePath, alt, type = 'user' }) => {
  if (type === 'company') {
    return (
      <Image
        cloudName="dunsguide"
        className="rounded-lg"
        publicId={imagePath || '/defaults/default_company_logo.png'}
        defaultImage="defaults:default_company_logo.png"
        alt={imagePath ? alt : 'Default company avatar'}
        width={size}
        height={size}
        crop="pad"
        background="white"
        dpr="auto"
      />
    );
  }
  if (imagePath) {
    return <UploadCareImage height={size} width={size} path={imagePath} alt={alt} />;
  }

  return <ReactAvatar name={name} size={`${size}px`} round />;
};

Avatar.propTypes = propTypes;

export { Avatar };
