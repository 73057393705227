import React from 'react';

import { StarIcon, BookmarkIcon, UsersIcon, BadgeCheckIcon } from '@heroicons/react/outline';
import { LogoutIcon, PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/_common/Avatar';
import { Dropdown } from 'components/_common/Dropdown';
import Link from 'components/Link';
import { ClaimRequestStatus } from 'store/models/Company';
import { User } from 'store/models/User';
import { getOtherCompanies } from 'utils/company';
import { isClaimApprovedAndHasDuns } from 'utils/company';
import { getCompanyPath } from 'utils/routes';

import { CompanyMenuItem } from './_components/CompanyMenuItem';
import { Label } from './_components/Label';

const AVATAR_SIZE_USER_OR_COMPANY = 40;
const AVATAR_SIZE_USER = 32; // Size for the user avatar in the dropdown when it's open

export type Props = {
  user: User;
  onLogoutClick(): void;
  onCompanyClick(id: string): void;
  className?: string;
};

function getDropdownAvatarProps(user: User): { name: string; imagePath: string; alt: string; type: string } {
  if (user.currentCompany) {
    return {
      name: user.currentCompany.primaryName,
      imagePath: user.currentCompany.logo,
      alt: `company_${user.currentCompany.primaryName}`,
      type: 'company',
    };
  }
  const userFullName = User.getUserFullName(user);
  return { name: userFullName, imagePath: user.image, alt: `user_${userFullName}`, type: 'user' };
}

function getUserFullNameOrCompanyName(user: User): string {
  if (user.currentCompany) {
    return user.currentCompany.primaryName;
  }
  return User.getUserFullName(user);
}

const UserDropdown = ({ user, onLogoutClick, onCompanyClick, className }: Props): React.JSX.Element => {
  const { t } = useTranslation();

  const userFullName = User.getUserFullName(user);

  const currentCompany = user.currentCompany;
  const otherCompanies = getOtherCompanies(user);

  return (
    <Dropdown
      position="default"
      direction="down"
      alignment="right"
      className={classNames('flex z-980 grow-0 flex-shrink-0', className)}
    >
      <Dropdown.Button variant="text-light">
        <Avatar {...getDropdownAvatarProps(user)} size={AVATAR_SIZE_USER_OR_COMPANY} />
        <span
          data-testid="text_user_or_current_company"
          className="hidden xl:inline-block pl-2 w-24 text-base font-medium leading-6 truncate"
        >
          {getUserFullNameOrCompanyName(user)}
        </span>
      </Dropdown.Button>

      <Dropdown.Items>
        <Dropdown.Section>
          <div className="px-4">
            <Label dataTestId="text_current_company" text={t('current company')} />
          </div>
          {currentCompany && (
            <>
              <Dropdown.Item
                className="p-0"
                content={
                  <Link
                    href={`${getCompanyPath(currentCompany)}/home`}
                    className="flex py-2 px-4 w-full"
                    data-testid="dropdown--home_link"
                  >
                    <div onClick={() => onCompanyClick(currentCompany.aclCompanyId)}>
                      <CompanyMenuItem company={currentCompany} />
                    </div>
                  </Link>
                }
              />

              {currentCompany.claimStatus === ClaimRequestStatus.APPROVED && (
                <Dropdown.Item
                  className="p-0"
                  content={
                    <Link
                      href={`${getCompanyPath(currentCompany)}/settings`}
                      className="flex py-2 px-4 w-full"
                      data-testid="dropdown--settings_link"
                    >
                      <BadgeCheckIcon className="mr-3 w-5 h-5 text-gray-900 group-hover:text-gray-500" />
                      <span data-testid="settings_button" className="text-sm font-normal leading-5 text-gray-700">
                        {t('subscription')}
                      </span>
                    </Link>
                  }
                />
              )}

              {isClaimApprovedAndHasDuns(user) && (
                <Dropdown.Item
                  className="p-0"
                  content={
                    <Link
                      href={`${getCompanyPath(currentCompany)}/users`}
                      className="flex py-2 px-4 w-full"
                      data-testid="dropdown--users_link"
                    >
                      <UsersIcon className="mr-3 w-5 h-5 text-gray-900 group-hover:text-gray-500" />
                      <span data-testid="users_button" className="text-sm font-normal leading-5 text-gray-700">
                        {t('users')}
                      </span>
                    </Link>
                  }
                />
              )}
            </>
          )}
        </Dropdown.Section>

        <Dropdown.Spacer />

        {otherCompanies.length > 0 && (
          <>
            <Dropdown.Section>
              <div className="px-4">
                <Label dataTestId="text_other_companies" text={t('other companies')} />
              </div>
              {otherCompanies.map((otherCompany) => {
                return (
                  <Dropdown.Item
                    key={otherCompany.aclCompanyId}
                    className="p-0"
                    content={
                      <Link
                        href={`${getCompanyPath(otherCompany)}/home`}
                        className="flex py-2 px-4 w-full"
                        data-testid="dropdown--other_company_link"
                      >
                        <div onClick={() => onCompanyClick(otherCompany.aclCompanyId)}>
                          <CompanyMenuItem company={otherCompany} />
                        </div>
                      </Link>
                    }
                  />
                );
              })}
            </Dropdown.Section>
          </>
        )}

        <Dropdown.Item
          className="p-0"
          content={
            <Link
              href="/claim-company"
              className="flex py-2 pr-4 pl-6 w-full"
              data-testid="dropdown--claim_company_link"
            >
              <PlusIcon className="mr-3 w-5 h-5 text-blue-600 group-hover:text-gray-500" />
              <span data-testid="add_company_button" className="text-sm font-normal leading-5 text-blue-600">
                {t('add company')}
              </span>
            </Link>
          }
        />

        <Dropdown.Spacer />

        <Dropdown.Section>
          <div className="px-4">
            <Label dataTestId="text_signed_in_as" text={t('signed in as')} />
          </div>
          <Dropdown.Item
            className="p-0"
            content={
              <Link href="/my-account" className="flex py-2 px-4 w-full" data-testid="dropdown--my_account_link">
                <div className="flex items-center">
                  <div className="flex-shrink-0 w-8 h-8">
                    <Avatar
                      name={userFullName}
                      imagePath={user.image}
                      alt={`user_avatar_${userFullName}`}
                      size={AVATAR_SIZE_USER}
                    />
                  </div>
                  <p
                    data-testid="text_user_full_name"
                    className="pl-2 w-40 text-sm font-normal leading-5 text-gray-700 truncate"
                  >
                    {userFullName}
                  </p>
                </div>
              </Link>
            }
          />
        </Dropdown.Section>

        <Dropdown.Item
          className="p-0"
          content={
            <Link href="/my-account/reviews" className="flex py-2 px-4 w-full" data-testid="dropdown--reviews_link">
              <StarIcon className="mr-3 w-5 h-5 text-gray-900 group-hover:text-gray-500" />
              <span data-testid="your_reviews_button" className="text-sm font-normal leading-5 text-gray-700">
                {t('your reviews')}
              </span>
            </Link>
          }
        />

        <Dropdown.Item
          className="p-0"
          content={
            <Link
              href="/my-account/favorites"
              className="flex items-center py-2 px-4 w-full"
              data-testid="dropdown--favorites_link"
            >
              <BookmarkIcon className="mr-3 w-5 h-5 text-gray-900 group-hover:text-gray-500" />
              <span data-testid="your_favorites_button" className="text-sm font-normal leading-5 text-gray-700">
                {t('your favorites')}
              </span>
            </Link>
          }
        />

        <Dropdown.Spacer />

        <Dropdown.Item
          className="p-0"
          content={
            <div className="flex items-center py-2 px-4 w-full">
              <LogoutIcon className="mr-3 w-5 h-5 text-gray-900 group-hover:text-gray-500" />
              <span data-testid="logout_button" className="text-sm font-normal leading-5 text-gray-700">
                {t('log out')}
              </span>
            </div>
          }
          onClick={onLogoutClick}
        />
      </Dropdown.Items>
    </Dropdown>
  );
};

export { UserDropdown };
