import { AdminCompanyDto } from './Company';
import { Workflow } from './Workflow';

interface DriftUserAttributes {
  email: string;
  phone: string;
  name: string;
  firstName: string;
  lastName: string;
  aclId: string;
}

export class User {
  static getUserFullName(user: User): string {
    return `${user.firstName} ${user.lastName}`;
  }

  static getDriftUserAttributes(user: User): DriftUserAttributes {
    return {
      email: user.email,
      phone: user.phone,
      name: user.name,
      firstName: user.firstName,
      lastName: user.lastName,
      aclId: user.aclId,
    };
  }

  id: string;
  email: string;
  name: string;
  aclId: string;
  language: string;
  firstName: string;
  lastName: string;
  image: string;
  title: string;
  phone: string;
  memberSince: Date;
  companies: AdminCompanyDto[];
  currentCompany?: AdminCompanyDto | null;
  workflows: Workflow[];
  settings?: UserSettingsDTO;
}

export class UserSettingsDTO {
  pulsoAiEnabled: boolean;
}

export class UserDto {
  id: string;
  firstName: string;
  lastName: string;
  image: string;
}

export class UpdateUserDTO {
  firstName: string;
  lastName: string;
  phone: string;
}

export enum InvitationStatusEnum {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
}

export class GetCompanyUsersDTO {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phone: string;
  language: string;
  timezone: string;
  status: string;
  avatar: string;
  invitationId?: string;
  invitationStatus: InvitationStatusEnum;
}
