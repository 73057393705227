import React from 'react';

import { useTranslation } from 'react-i18next';

import { Badge } from '_ui/_common/Badge';

interface PredefinedBadgeProps {
  type: 'duns_registered' | 'esg_registered' | 'duns_credibility' | 'duns_credibility_premium' | 'duns_100';
}

const colorByType = (type: string) => {
  switch (type) {
    case 'duns_registered':
      return 'blue';
    case 'esg_registered':
      return 'green';
    case 'duns_credibility':
      return 'indigo';
    case 'duns_credibility_premium':
      return 'orange';
    case 'duns_100':
      return 'gray';
  }
};

const PredefinedBadge = ({ type }: PredefinedBadgeProps): React.JSX.Element => {
  const { t } = useTranslation('company');
  const color = colorByType(type);

  return <Badge color={color}>{t(`company_seals.${type}.title`)}</Badge>;
};

export { PredefinedBadge };
