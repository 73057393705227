import React, { Fragment, useContext } from 'react';

import { HomeIcon, SearchIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { RouterContext } from 'components/_context/RouterContext';
import NavLink from 'components/_nav/NavLink';
import { AdminCompanyDto } from 'store/models/Company';
import { User } from 'store/models/User';
import { getLanguageFromPathName } from 'utils/locale';
import { getCompanyPath } from 'utils/routes';

import { PulseIcon } from './_components/PulseIcon';
import { MainNavLink } from './constants';
import { getMainDynamicNavLinks } from './utils';

type NavLinkStyles = {
  color?: 'text-white' | 'text-gray-500'; // link text and icon
  direction?: 'flex-row' | 'flex-col'; // direction of icon and text
  hoverColorIcon?: 'text-white' | 'text-blue-600';
  hoverColorText?: 'text-white' | 'text-gray-900';
  linkActiveColor?: 'bg-[#00628A]' | 'bg-gray-50';
  container?: 'flex' | 'flex flex-1';
  containerPadding?: 'px-4' | 'p-0';
};

type MainNavComponentsMappingProps = {
  t: TFunction;
  currentCompany?: AdminCompanyDto | null;
  styles?: NavLinkStyles;
};

const defaultNavLinkStyles: NavLinkStyles = {
  color: 'text-white',
  direction: 'flex-row',
  hoverColorIcon: 'text-white',
  hoverColorText: 'text-white',
  linkActiveColor: 'bg-[#00628A]',
  container: 'flex',
  containerPadding: 'px-4',
};

export function getCurrentRoute(pathName: string): string {
  const language = getLanguageFromPathName(pathName);
  if (language) {
    return pathName.substring(3);
  }
  return pathName;
}

export function getLinkHrefsMetaData(
  linkName: MainNavLink,
  currentCompany?: AdminCompanyDto | null,
): { default: string; matcher: string[] } {
  const linkHrefs = {
    [MainNavLink.SEARCH]: { default: '/companies', matcher: ['/companies'] },
    [MainNavLink.PLANS]: { default: '/plans', matcher: ['/plans'] },
    [MainNavLink.LIST_MY_BUSINESS]: { default: '/list-my-business', matcher: ['/list-my-business'] },
    [MainNavLink.HOME_USER]: {
      default: '/my-account',
      matcher: ['/my-account', '/my-account/reviews', '/my-account/favorites'],
    },
    [MainNavLink.DISCOVER]: { default: '/companies', matcher: ['/companies'] },
    [MainNavLink.PULSO]: { default: '/pulso', matcher: ['/pulso'] },
  };

  if (currentCompany) {
    const companyPath = getCompanyPath(currentCompany);
    const companyHomePath = `${companyPath}/home`;
    const companyPulsoPath = `${companyPath}/pulso`;
    const companyProfilePath = `${companyPath}/profile`;
    linkHrefs[MainNavLink.HOME_COMPANY] = { default: companyHomePath, matcher: [companyHomePath] };
    linkHrefs[MainNavLink.PULSO] = {
      default: companyPulsoPath,
      matcher: [
        companyPulsoPath,
        `${companyPulsoPath}/financial-health`,
        `${companyPulsoPath}/growth-and-results`,
        `${companyPulsoPath}/compliance-and-reputation`,
        `${companyPulsoPath}/credit-score`,
      ],
    };
    linkHrefs[MainNavLink.PROFILE] = {
      default: companyProfilePath,
      matcher: [companyProfilePath, `${companyPath}/reviews`, `${companyPath}/analytics`, `${companyPath}/settings`],
    };
  }

  return linkHrefs[linkName];
}

type NavComponentProps = {
  href: string;
  activeLinkClassName?: string;
  activeIconClassName?: string;
  activeTextClassName?: string;
};

export const mainNavComponentsMapping = ({
  t,
  currentCompany,
  styles = defaultNavLinkStyles,
}: MainNavComponentsMappingProps): {
  [key: string]: React.FC<{
    href: string;
    activeLinkClassName?: string;
    activeIconClassName?: string;
    activeTextClassName?: string;
  }>;
} => {
  const containerClassName = `${styles.container} self-start xl:self-center items-center justify-center ${styles.containerPadding} rounded-md hover:bg-[#00628A] h-full`;
  const textClassName = `truncate text-lg font-normal leading-7 ${styles.color} hover:${styles.hoverColorText}`;
  const iconClassName = `w-6 ${styles.color} hover:${styles.hoverColorIcon}`;
  const iconTextContainerClassName = `flex ${styles.direction} gap-x-2 justify-center items-center text-center`;

  const navigationLinks = {
    /* not logged in */
    [MainNavLink.SEARCH]: ({ href, activeLinkClassName, activeTextClassName }: NavComponentProps) => (
      <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
        <span className={twMerge(classnames(textClassName, activeTextClassName))} data-testid="link_search">
          {t('find companies')}
        </span>
      </NavLink>
    ),
    [MainNavLink.PLANS]: ({ href, activeLinkClassName, activeTextClassName }: NavComponentProps) => (
      <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
        <span
          className={twMerge(classnames(textClassName, activeTextClassName))}
          data-testid="link_plans"
          onClick={() => window.analytics?.track('primary_nav--plans--clicked​', { type: 'Plans' })}
        >
          {t('plans')}
        </span>
      </NavLink>
    ),
    [MainNavLink.PULSO]: ({
      href,
      activeLinkClassName,
      activeIconClassName,
      activeTextClassName,
    }: NavComponentProps) => (
      <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
        <div className={iconTextContainerClassName}>
          <PulseIcon className={twMerge(classnames(iconClassName, activeIconClassName))} />

          <span className={twMerge(classnames(textClassName, activeTextClassName))} data-testid="link_pulso">
            {t('pulso')}
          </span>
        </div>
      </NavLink>
    ),
    [MainNavLink.LIST_MY_BUSINESS]: ({
      href,
      activeLinkClassName,
      activeIconClassName,
      activeTextClassName,
    }: NavComponentProps) => (
      <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
        <div className={iconTextContainerClassName}>
          <PlusIcon className={twMerge(classnames(iconClassName, activeIconClassName))} />
          <span
            onClick={() =>
              window.analytics?.track('primary_nav--list_business--clicked​', { type: 'List my business' })
            }
            className={twMerge(classnames(textClassName, activeTextClassName))}
            data-testid="link_list_my_business"
          >
            {t('list my business')}
          </span>
        </div>
      </NavLink>
    ),

    /* logged in */
    // no company
    [MainNavLink.HOME_USER]: ({
      href,
      activeLinkClassName,
      activeIconClassName,
      activeTextClassName,
    }: NavComponentProps) => (
      <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
        <div className={iconTextContainerClassName}>
          <HomeIcon className={twMerge(classnames(iconClassName, activeIconClassName))} />
          <span className={twMerge(classnames(textClassName, activeTextClassName))} data-testid="link_home_user">
            {t('home')}
          </span>
        </div>
      </NavLink>
    ),
    [MainNavLink.DISCOVER]: ({
      href,
      activeLinkClassName,
      activeIconClassName,
      activeTextClassName,
    }: NavComponentProps) => (
      <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
        <div className={iconTextContainerClassName}>
          <SearchIcon className={twMerge(classnames(iconClassName, activeIconClassName))} />
          <span className={twMerge(classnames(textClassName, activeTextClassName))} data-testid="link_home_discover">
            {t('discover')}
          </span>
        </div>
      </NavLink>
    ),
    // with company
    ...(currentCompany
      ? {
          [MainNavLink.HOME_COMPANY]: ({
            href,
            activeLinkClassName,
            activeIconClassName,
            activeTextClassName,
          }: NavComponentProps) => (
            <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
              <div className={iconTextContainerClassName}>
                <HomeIcon className={twMerge(classnames(iconClassName, activeIconClassName))} />
                <span
                  className={twMerge(classnames(textClassName, activeTextClassName))}
                  data-testid="link_home_company"
                >
                  {t('home')}
                </span>
              </div>
            </NavLink>
          ),
          [MainNavLink.PULSO]: ({
            href,
            activeLinkClassName,
            activeIconClassName,
            activeTextClassName,
          }: NavComponentProps) => (
            <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
              <div className={iconTextContainerClassName}>
                <PulseIcon className={twMerge(classnames(iconClassName, activeIconClassName))} />

                <span className={twMerge(classnames(textClassName, activeTextClassName))} data-testid="link_pulso">
                  {t('pulso')}
                </span>
              </div>
            </NavLink>
          ),
          [MainNavLink.PROFILE]: ({
            href,
            activeLinkClassName,
            activeIconClassName,
            activeTextClassName,
          }: NavComponentProps) => (
            <NavLink className={classnames(containerClassName, activeLinkClassName)} href={href}>
              <div className={iconTextContainerClassName}>
                <OfficeBuildingIcon className={twMerge(classnames(iconClassName, activeIconClassName))} />
                <span
                  className={twMerge(classnames(textClassName, activeTextClassName))}
                  data-testid="link_home_profile"
                >
                  {t('profile')}
                </span>
              </div>
            </NavLink>
          ),
        }
      : {}),
  };

  return navigationLinks;
};

type Props = {
  user?: User;
  styles?: NavLinkStyles;
};

const MainDynamicNavigation = ({ user, styles }: Props): React.JSX.Element => {
  const { t } = useTranslation();
  const { router } = useContext(RouterContext);

  const linkStyles = { ...defaultNavLinkStyles, ...styles };
  const mainNavLinks = getMainDynamicNavLinks(user);
  const currentRoute = getCurrentRoute(router.asPath);

  const MainNavComponentsMap = mainNavComponentsMapping({
    t,
    currentCompany: user?.currentCompany,
    styles: linkStyles,
  });

  return (
    <>
      {mainNavLinks.map((linkName) => {
        const NavComponent = MainNavComponentsMap[linkName];

        const { default: linkHref, matcher } = getLinkHrefsMetaData(linkName, user?.currentCompany);

        const highlightClassNames = matcher.includes(currentRoute)
          ? {
              activeLinkClassName: linkStyles.linkActiveColor,
              activeIconClassName: linkStyles.hoverColorIcon,
              activeTextClassName: linkStyles.hoverColorText,
            }
          : {};

        return (
          <Fragment key={linkName}>
            <NavComponent href={linkHref} {...highlightClassNames} />
          </Fragment>
        );
      })}
    </>
  );
};

export { MainDynamicNavigation };
