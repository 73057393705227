import React from 'react';

import UploadcareImage from '@uploadcare/nextjs-loader';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const propTypes = {
  path: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

const UploadCareImage = ({ path, alt, width = 100, height = 100 }) => {
  const { t } = useTranslation('common');

  const isS3Path = path.includes('il-acl.s3');

  return isS3Path ? (
    <Image
      alt={alt}
      src={path}
      width={width}
      height={height}
      aria-hidden="true"
      style={{ maxWidth: '100%', objectFit: 'cover' }}
      className={`self-center rounded-full w-[${width}px] h-full`}
    />
  ) : (
    <UploadcareImage
      src={path}
      width={width}
      height={height}
      alt={path ? alt : t('Default company person')}
      className={`self-center rounded-full w-[${width}px] h-full`}
    />
  );
};

UploadCareImage.propTypes = propTypes;

export default UploadCareImage;
