/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

import { RouterContext } from 'components/_context/RouterContext';

const propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
  // Accepts Next Link props
  // Accepts Nav.Link props
};

const NavLink = ({ children, href, isExternal = false, ...rest }) => {
  const { getLocalizedPath } = useContext(RouterContext);

  const NavLinkComponent = React.forwardRef(({ onClick, href: passedHref }, ref) => (
    <Nav.Link {...rest} href={passedHref} ref={ref} onClick={onClick}>
      {children}
    </Nav.Link>
  ));

  return (
    <NextLink href={isExternal ? href : getLocalizedPath(href)} passHref {...rest} legacyBehavior>
      <NavLinkComponent />
    </NextLink>
  );
};

NavLink.propTypes = propTypes;

export default NavLink;
