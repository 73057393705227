import React from 'react';

import classnames from 'classnames';

type Props = {
  cols?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  gap?: number | boolean;
  children?: React.ReactNode;
  className?: string;
};

const Grid = ({ cols = 12, xs, sm, md, lg, xl, gap, className = '', children, ...restProps }: Props) => {
  const gapValue = gap === true ? 8 : gap;

  const classNames = classnames(`grid grid-auto-flow grid-cols-${cols}`, {
    [`grid-cols-${cols}`]: cols,
    [`xs:grid-cols-${xs}`]: xs,
    [`sm:grid-cols-${sm}`]: sm,
    [`md:grid-cols-${md}`]: md,
    [`lg:grid-cols-${lg}`]: lg,
    [`xl:grid-cols-${xl}`]: xl,
    [`gap-${gapValue}`]: gapValue,
    [className]: !!className,
  });

  return (
    <div className={classNames} {...restProps}>
      {children}
    </div>
  );
};

export default Grid;
