import React from 'react';

type LabelProps = {
  text: string;
  dataTestId: string;
};

const Label = ({ text, dataTestId }: LabelProps) => {
  return (
    <span data-testid={dataTestId} className="text-xs font-semibold tracking-wide leading-5 text-gray-500 uppercase">
      {text}
    </span>
  );
};

export { Label };
