import React, { ReactNode } from 'react';

import { XIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';

import Link from 'components/Link';

import { PredefinedBadge } from './_components/PredefinedBadge';

export type BadgeProps = {
  children?: string | ReactNode;
  href?: string;
  dot?: boolean;
  className?: string;
  onClose?: () => void;
  removeButton?: boolean;
  size?: 'default' | 'lg' | 'xl';
  shape?: 'default' | 'rounded';
  color?: 'gray' | 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink' | 'orange' | 'lightBlue';
  type?: 'default' | 'processTag';
};

const Badge = ({
  children,
  dot,
  href,
  onClose,
  className,
  removeButton,
  color = 'gray',
  size = 'default',
  shape = 'default',
  type = 'default',
}: BadgeProps): React.JSX.Element => {
  const renderBadge = (withLink = false): React.JSX.Element => {
    const displayClass = withLink ? 'flex' : 'inline-flex';

    const badgeClasses = twMerge(
      classnames(
        `${displayClass} justify-center items-center font-medium whitespace-nowrap ${
          type === 'processTag' ? `text-white bg-lightBlue/[.15]` : `bg-${color}-100 text-${color}-800`
        }`,
        {
          'rounded-full': shape === 'default' && size === 'default',
          'rounded-xl': (shape === 'default' && size === 'lg') || (shape === 'rounded' && size === 'xl'),
          rounded: shape === 'rounded' && size !== 'xl',
        },
        {
          'py-0.5 px-2.5 text-xs leading-4': size === 'default',
          'py-0.5 px-3 text-sm leading-5': size === 'lg',
          'py-1 px-4 text-2xl leading-8 font-bold': size === 'xl',
        },
        {
          'pl-2': dot,
          'pr-1': removeButton,
        },
        className,
      ),
    );

    return (
      <span className={badgeClasses} data-testid="common_badge">
        {dot && (
          <svg viewBox="0 0 8 8" fill="currentColor" className={`mr-1.5 h-2 w-2 text-${color}-400`}>
            <circle cx="4" cy="4" r="3" />
          </svg>
        )}

        {children}

        {removeButton && (
          <button
            type="button"
            onClick={onClose}
            className="flex justify-center items-center p-1 grow-0"
            data-testid="badge--remove_button"
          >
            <XIcon className={`w-3.5 h-3.5 text-${color}-400`} />
          </button>
        )}
      </span>
    );
  };

  if (href) {
    return <Link href={href}>{renderBadge(true)}</Link>;
  }

  return renderBadge();
};

export { Badge, PredefinedBadge };
