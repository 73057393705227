import React from 'react';

import classNames from 'classnames';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import Column from 'components/_designSystem/_layout/Column';
import Container from 'components/_designSystem/_layout/Container';
import Grid from 'components/_designSystem/_layout/Grid';
import ColumnListLinks from 'components/_listItems/ColumnListLinks';

import styles from './ListItems.module.scss';

const propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
  cols: PropTypes.number,
  title: PropTypes.string,
  footer: PropTypes.object,
  titleClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  footerClassName: PropTypes.string,
};

// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const mdColStartVariants = {
  // 1 and 3 are the only ones we use right now
  1: 'md:col-start-1',
  2: 'md:col-start-2',
  3: 'md:col-start-3',
  4: 'md:col-start-4',
  5: 'md:col-start-5',
  6: 'md:col-start-6',
  7: 'md:col-start-7',
  8: 'md:col-start-8',
  9: 'md:col-start-9',
  10: 'md:col-start-10',
  11: 'md:col-start-11',
  12: 'md:col-start-12',
};

const ListItems = ({ values, titleClassName, itemClassName, footerClassName, cols, title, footer }) => {
  const titleClass = classNames(styles.title, { [titleClassName]: !isNil(titleClassName) });
  const itemClass = classNames(styles.item, { [itemClassName]: !isNil(itemClassName) });
  const footerClass = classNames(styles.footer, { [footerClassName]: !isNil(footerClassName) });

  return (
    <Container>
      <div className={titleClass}>{title}</div>
      <ColumnListLinks cols={cols} values={values} itemClass={itemClass} />
      <Grid cols={cols} className="gap-0 md:gap-8">
        <Column className={`col-auto col-start-1 ${mdColStartVariants[cols]}`}>
          <div className={footerClass}>{footer}</div>
        </Column>
      </Grid>
    </Container>
  );
};

ListItems.propTypes = propTypes;

export default ListItems;
