import React from 'react';

import { Image } from 'cloudinary-react';
import PropTypes from 'prop-types';

const propTypes = {
  path: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  background: PropTypes.string,
  crop: PropTypes.string,
};

const GalleryImage = ({ path, alt, className, crop = 'pad', background = 'white', width = 100, ...rest }) => (
  <Image
    cloudName="dunsguide"
    className={className}
    publicId={path}
    alt={alt}
    width={width}
    crop={crop}
    background={background}
    dpr="auto"
    {...rest}
  />
);

GalleryImage.propTypes = propTypes;

export default GalleryImage;
