import React from 'react';

import classnames from 'classnames';
import PropTypes, { number } from 'prop-types';

const propTypes = {
  col: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

const Column = ({
  col = undefined | number,
  sm = undefined | number,
  md = undefined | number,
  lg = undefined | number,
  xl = undefined | number,
  className = '',
  children,
  ...restProps
}) => {
  const classNames = classnames('col-auto', {
    [`col-span-${col}`]: col,
    [`sm:col-span-${sm}`]: sm,
    [`md:col-span-${md}`]: md,
    [`lg:col-span-${lg}`]: lg,
    [`xl:col-span-${xl}`]: xl,
    [className]: !!className,
  });

  return (
    <div className={classNames} {...restProps}>
      {children}
    </div>
  );
};

Column.propTypes = propTypes;

export default Column;
